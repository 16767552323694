<template>
	
	<div class="cont" id="p06">
		<div class="c01">
			<div class="left">
				<div class="years">
					<div class="btn-prev"
						:class="{ active: isActive.prev }"
						@click="prev"><i class="xi-angle-left"></i></div>
					<div class="year-now">{{ search.inquiryMonth.substring(0, 4) }}. {{ search.inquiryMonth.substring(4, 6) }}</div>
					<div class="btn-next"
						:class="{ active: isActive.next }"
						@click="next"><i class="xi-angle-right"></i></div>
					
				</div>
				<div class="month-fee">
					
				</div>
				<div class="months ">
					<template
						v-for="(item, index) in items"
					>
					<div class="month"
						:class="classToday(item.day)"
						:key="index"
					>
						<div class="title">{{ item.day }}일</div>
						<div class="fees">
							<div class="fee" style="margin: 0 !important;">
								<span class="desc em">총 결제건</span>
								<span class="amount em">{{ item.totalPaymentCount | makeComma }}건</span>
							</div>
						</div>
						<div class="total">
							<div class="all">
								<div class="count">정산불가</div>
								<div class="price">{{ item.waitingSettlementAmount | makeComma }}원</div>
							</div>
						</div>
						<div class="fees">
							<div class="fee">
								<span class="desc em">정산완료</span>
								<span class="amount em">{{ item.completedSettlementAmount | makeComma }}원</span>
							</div>
						</div>
					</div>
					</template>
				</div>
			</div>
			
			<div class="right">
				<div class="group g01"
				>
					<router-link :to="{ name: 'AgentSalesList' }">
						<div class="title">
							<div class="gt-l">
								<span class="p1">계약한도</span>
							</div>
							<div class="gt-r">
							</div>
						</div>
					</router-link>
					<div class="content01" style="padding: 20px;">
						<div class="cp" style="margin-bottom: 20px !important;">연한도 <span class="float-right"> {{ summary.limit.yearLimitAmount | makeComma}}원</span></div>
						<div class="cp">잔여한도 <span class="float-right"> {{ summary.limit.remainingLimitAmount  | makeComma}}원</span></div>
					</div>
				</div>

				<div class="group g02"
				>
					<router-link :to="{ name: 'AgentSalesList' }">
						<div class="title">
							<div class="gt-l">
								<span class="p1">정산완료</span>
							</div>
							<div class="gt-r">
							</div>
						</div>
					</router-link>
					<div class="content01">
						<div class="cp" style="margin-bottom: 20px !important;">결제건 <span class="float-right"> {{ summary.monthSettlement.completedSettlementCount | makeComma }}건</span></div>
						<div class="cp">금액 <span class="float-right"> {{ summary.monthSettlement.completedSettlementAmount | makeComma }}원</span></div>
					</div>
				</div>

				<div class="group g02"
				>
					<router-link :to="{ name: 'AgentSalesList' }">
						<div class="title">
							<div class="gt-l">
								<span class="p1">정산 불가</span>
							</div>
							<div class="gt-r">
							</div>
						</div>
					</router-link>
					<div class="content01">
						<div class="cp" style="margin-bottom: 20px !important;">결제건 <span class="float-right">{{ summary.monthSettlement.waitingSettlementCount | makeComma }}건</span></div>
						<div class="cp">금액 <span class="float-right">{{ summary.monthSettlement.waitingSettlementAmount | makeComma }}원</span></div>
					</div>
				</div>

				<div class="group g03">
					<router-link :to="{ name: 'MerchantsList' }">
						<div class="title">
							<div class="gt-l">
								<span class="p1">서비스 수수료</span>
							</div>
							<div class="gt-r">
							</div>
						</div>
					</router-link>
					<div class="content02">
						<div class="citem"
							v-for="(item, index) in user.paymentItems"
							:key="index"
						>
							<div class="cp">{{ item.serviceItemName }}</div>
							<div class="camount">{{ item.merchantCommission | makeComma }} %</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>


<script>

	import { date } from '@/resources/date/date'
	import { filter } from '@/resources/filter/filter'
	import { Axios } from '@/resources/axios/axios'
	
	export default{
		name: 'DashBoard'
		,props: ['user']
		,components: {  }
		,data: function(){
			return {
				program: {
					name: 'DashBoard'
				}
				,summary: {
					inquiryMonth: ''
					,limit: {
					}
					,monthSettlement: {
					}
				}
				,total: {
					dealerCount: 0,
					dealerIncreaseCount: 0,
					inquiryMonth: 0,
					merchantCount: 0,
					merchantRejectCount: 0,
					merchantWaitingCount: 0,
					resellerCount: 0,
					resellerIncreaseCount: 0
				}
				,items: {
				
				}
				,search: {
					inquiryYear: date.init().year
					,inquiryMonth: date.init().year + "" + date.init().month
					,inquiryYearMonth: date.init().month
				}
				,isActive: {
					prev: true
					,next: false
				}
			}
		}
		,filter: filter
		,methods: {
			getData: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/dashboard'
						,data: this.search
						,authorize: true
					})
					if(result.success){
						this.items = result.data.content.calendar
						this.summary.inquiryMonth = result.data.content.inquiryMonth
						this.summary.limit = result.data.content.limit
						this.summary.monthSettlement = result.data.content.monthSettlement
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E})
				}
			}
			,getTotal: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/dashboard/member'
						,data: this.search
						,authorize: true
					})
					if(result.success){
						this.total = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E})
				}
			}
			
			,prev: async function(){
				let now = date.getToday()
				if(now.replaceAll('-', '').substring(0, 6) >= this.search.inquiryMonth){
					this.isActive.next = true
				}
				this.search.inquiryYearMonth = ("0" + (this.search.inquiryYearMonth - 1)).slice(-2)
				if(this.search.inquiryYearMonth <= 0){
					this.search.inquiryYearMonth = 12
					this.search.inquiryYear--
				}
				this.search.inquiryMonth = this.search.inquiryYear + "" + this.search.inquiryYearMonth
				await this.getData()
			}
			,next: async function(){
				let now = date.getToday()
				if(now.replaceAll('-', '').substring(0, 6) <= this.search.inquiryMonth){
					return false
				}
				this.search.inquiryYearMonth = ("0" + (Number(this.search.inquiryYearMonth) + 1)).slice(-2)
				console.log(this.search.inquiryYearMonth)
				if(this.search.inquiryYearMonth > 12){
					this.search.inquiryYearMonth = '01'
					this.search.inquiryYear++
				}
				this.search.inquiryMonth = this.search.inquiryYear + "" + this.search.inquiryYearMonth
				console.log(this.search.inquiryMonth)
				if(now.replaceAll('-', '').substring(0, 6) <= this.search.inquiryMonth){
					this.isActive.next = false
				}
				await this.getData()
			}
			,classToday: function(day){
				if(this.search.inquiryMonth + ("0" + day).slice(-2) == date.getToday()){
					return 'active'
				}else if(this.search.inquiryMonth + ("0" + day).slice(-2) > date.getToday()){
					return 'deactive'
				}else{
					return ''
				}
			}
		}
		,created: function(){
			if(this.user.basic.level == 1){
				this.$emit('setProgram', this.program)
				this.getData()
			}else{
				this.$router.push({ name: 'PaymentsHistory' })
			}
		}
	}
</script>


<style>
	#p06 .left { width: 1600px !important;}
	#p06 .left .month { width: calc(14.28% - 8.5px) !important; margin-right: 10px !important;}
	#p06 .left .month:nth-child(7n) { margin-right: 0px !important;}
	.deactive { opacity: 0.5;}
</style>





















